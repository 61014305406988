"use client";

import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import Image from "next/image";

const navigation = [
  { name: "About Us", href: "/about-us" },
  {
    name: "Services",
    href: null,
    submenu: [
      {
        name: "Website Design",
        href: "/services/website-design-and-development",
        submenu: [
          {
            name: "Website Design",
            href: "/services/website-design-and-development",
          },
          {
            name: "Next.js Website",
            href: "/services/next.js-website-design-services",
          },
          {
            name: "Custom Wordpress Website",
            href: "/services/custom-wordpress-website",
          },
          {
            name: "Wordpress Design Services",
            href: "/services/wordpress-website-design-services",
          },
          {
            name: "PSD to Wordpress",
            href: "/services/psd-to-wordpress",
          },
          {
            name: "Landing Page Design Services",
            href: "/services/landing-page-design-services",
          },
          {
            name: "Headless CMS Solution",
            href: "/services/headless-cms-solution",
          },
          {
            name: "Static Site Generators",
            href: "/services/static-site-generators",
          },
          {
            name: "UX/UI Design Services",
            href: "/services/ux-ui-design-services",
          },
          {
            name: "Website Maintenance Services",
            href: "/services/website-maintenance-services",
          },
        ],
      },
      {
        name: "Ecommerce Website",
        href: "/services/ecommerce-website-design",
        submenu: [
          {
            name: "Ecommerce Website",
            href: "/services/ecommerce-website-design",
          },
          {
            name: "Shopify Website Design",
            href: "/services/shopify-website-design-services",
          },
          {
            name: "PSD to Shopify",
            href: "/services/psd-to-shopify",
          },

          {
            name: "Shopware Theme Design",
            href: "/services/shopware-theme-design",
          },
        ],
      },
      {
        name: "Digital Marketing",
        href: "/services/digital-marketing",
        submenu: [
          {
            name: "Digital Marketing",
            href: "/services/digital-marketing",
          },
          {
            name: "Digital Marketing Strategy",
            href: "/services/digital-marketing-strategy",
          },
          { name: "SEO Services", href: "/services/seo-services" },
          { name: "Content Creation", href: "/services/content-creation" },
          {
            name: "Social Media Graphic Design",
            href: "/services/social-media-graphic-design",
          },
          {
            name: "Website Design and SEO Services",
            href: "/services/website-design-and-seo-services",
          },
        ],
      },
      {
        name: "Web App Development",
        href: "/services/web-app-development",
        submenu: [
          {
            name: "Web App Development",
            href: "/services/web-app-development",
          },
          {
            name: "Web Portal Development",
            href: "/services/web-portal-development",
          },
        ],
      },
      { name: "Mobile Application", href: "/services/mobile-app-development" },
      { name: "Saas Website Design", href: "/services/saas-website-design" },
      {
        name: "Graphic Design",
        href: "/services/graphic-design-services",
        submenu: [
          {
            name: "Digital Design Services",
            href: "/services/digital-design-services",
          },
        ],
      },
      { name: "AI Consulting", href: "/services/ai-consulting" },
    ],
  },
  {
    name: "Technology",
    href: null,
    submenu: [
      { name: "Next.js", href: "/services/nextjs-development-services" },
      { name: "React.js", href: "/services/react.js-development-services" },
      { name: "Shopify", href: "/services/shopify-website-design-services" },
      { name: "Laravel", href: "/services/laravel-development-services" },
      {
        name: "React Native",
        href: "/services/react-native-development-services",
      },
      { name: "Gatsby.js", href: "/services/gatsby-website-design-services" },
      {
        name: "Custom Wordpress Development",
        href: "/services/custom-wordpress-development-services",
      },
    ],
  },
  {
    name: "Industry",
    href: null,
    submenu: [
      {
        name: "Architect and Interior Designers",
        href: "/industry/architect-and-interior-designers",
      },
      { name: "Beauty and Fashion", href: "/industry/beauty-and-fashion" },
      { name: "Charity and Non-profit", href: "/industry/charity" },
      { name: "Clothing and Apparel", href: "/industry/clothing-and-apparel" },
      {
        name: "Construction Company",
        href: "/industry/construction-web-page-design-services",
      },
      { name: "Electronics", href: "/industry/electronics" },
      { name: "Family Lawyer", href: "/industry/web-design-for-family-lawyer" },
      { name: "Finance", href: "/industry/finance" },
      { name: "Healthcare", href: "/industry/healthcare" },
      { name: "Home Services", href: "/industry/home-services-web-design" },
      { name: "Hospitality", href: "/industry/hospitality" },
      {
        name: "HVAC Web Design",
        href: "/industry/hvac-website-design-services",
      },
      {
        name: "Locksmith Website Design",
        href: "/industry/locksmith-web-design-services",
      },
      { name: "Online Courses", href: "/industry/online-courses" },
      {
        name: "Painter Website Design",
        href: "/industry/painter-web-design-services",
      },
      {
        name: "Pest Control Website Design",
        href: "/industry/pest-control-web-design-services",
      },
      { name: "Real Estate", href: "/industry/real-estate" },
      {
        name: "Small Business",
        href: "/industry/affordable-web-design-for-small-business",
      },
    ],
  },
  {
    name: "Portfolio",
    href: "/portfolio",
    submenu: [
      {
        name: "Portfolio",
        href: "/portfolio",
      },
      {
        name: "Website Design",
        href: "/portfolio/websitedesign",
      },
      { name: "Ecommerce", href: "/portfolio/ecommerce" },
      { name: "Logo", href: "/portfolio/logo" },
      { name: "Social Media", href: "/portfolio/social-media" },
      { name: "Graphic Design", href: "/portfolio/graphic-design" },
      { name: "Mobile", href: "/portfolio/mobile" },
    ],
  },
  { name: "Blog", href: "/blog/" },
  { name: "Contact Us", href: "/contact-us" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [openSubSubmenu, setOpenSubSubmenu] = useState(null);
  const [scrollDirection, setScrollDirection] = useState("up");

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
    setOpenSubSubmenu(null); // Close any open sub-submenus when a new submenu is toggled
  };

  const handleSubSubmenuToggle = (index) => {
    setOpenSubSubmenu(openSubSubmenu === index ? null : index);
  };

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    setOpenSubSubmenu(null);
    close(); // Close the Disclosure panel
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={classNames(
        "sticky top-0 z-[999] w-full transition-transform duration-300 font-bold tracking-wider bg-white font-sans",
        scrollDirection === "down" ? "-translate-y-full" : "translate-y-0"
      )}
    >
      <div className="w-full bg-neutral-600 ">
        <div className="flex flex-col md:flex-row max-w-screen-2xl justify-center md:justify-between items-center mx-auto">
          <div className="hidden md:block text-base text-white py-2">
            Website and Mobile Designers and Digital Marketing Experts
          </div>
          <div className="flex py-2 space-x-6 text-neutral-300">
            <a
              href="https://www.linkedin.com/in/prateekshaweb/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 64 64"
                className="w-6 h-6"
                fill="currentColor"
              >
                <path d="M40.227,12C51.145,12,52,12.854,52,23.773v16.453C52,51.145,51.145,52,40.227,52H23.773C12.855,52,12,51.145,12,40.227	V23.773C12,12.854,12.855,12,23.773,12H40.227z M25.029,43V26.728h-5.057V43H25.029z M22.501,24.401	c1.625,0,2.947-1.322,2.947-2.949c0-1.625-1.322-2.947-2.947-2.947c-1.629,0-2.949,1.32-2.949,2.947S20.87,24.401,22.501,24.401z M44,43v-8.925c0-4.382-0.946-7.752-6.067-7.752c-2.46,0-4.109,1.349-4.785,2.628H33.08v-2.223h-4.851V43h5.054v-8.05	c0-2.122,0.405-4.178,3.036-4.178c2.594,0,2.628,2.427,2.628,4.315V43H44z"></path>
              </svg>
            </a>
            <a
              className="hover:opacity-75"
              href="https://www.facebook.com/prateekshaweb/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="sr-only"> Facebook </span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            <a
              className="hover:opacity-75"
              href="https://www.instagram.com/prateekshawebdesigners/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="sr-only"> Instagram </span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            <a
              className="hover:opacity-75"
              href="https://twitter.com/PrateekshaWeb"
              target="_blank"
              rel="noreferrer"
            >
              <span className="sr-only"> Twitter </span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
            <a
              className="hover:opacity-75"
              href="https://github.com/prateekshaweb"
              target="_blank"
              rel="noreferrer"
            >
              <span className="sr-only"> GitHub </span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="max-w-screen-2xl mx-auto">
        <Disclosure as="nav" className="bg-white">
          {({ open, close }) => (
            <>
              <div className="w-full mx-auto px-2 sm:px-6 lg:px-8 py-2">
                <div className="relative flex items-center justify-between sm:h-24">
                  <div className="inset-y-0 left-0 flex items-center sm:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex-shrink-0">
                    <Link href="/">
                      <Image
                        src="/images/logo.png"
                        alt="Prateeksha Web Design logo"
                        width={226}
                        height={68}
                        className="w-36 sm:w-auto"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:flex flex-1 items-end justify-end">
                    <div className="flex space-x-4">
                      {navigation.map((item, index) =>
                        item.submenu ? (
                          <div key={item.name} className="relative submenu">
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-black text-lg px-2 py-2 rounded-md font-medium flex items-center hover:text-orange-400"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute z-10 shadow-lg rounded-md py-2 mt-1 w-72 bg-color-brown-100">
                                {item.submenu.map((subitem, subIndex) =>
                                  subitem.submenu ? (
                                    <div
                                      key={subitem.name}
                                      className="relative submenu"
                                    >
                                      <button
                                        onClick={() =>
                                          handleSubSubmenuToggle(subIndex)
                                        }
                                        className="block2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-orange-400 font-semibold w-full text-left flex items-center justify-between"
                                      >
                                        {subitem.name}
                                        <ChevronDownIcon
                                          className={`ml-2 h-4 w-4 transition-transform ${
                                            openSubSubmenu === subIndex
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                      {openSubSubmenu === subIndex && (
                                        <div className="absolute left-full top-0 z-20 shadow-lg rounded-md py-2 mt-1 w-72 bg-color-brown-100">
                                          {subitem.submenu.map((subSubItem) => (
                                            <Link
                                              key={subSubItem.name}
                                              href={subSubItem.href}
                                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-orange-400 font-semibold"
                                              onClick={() =>
                                                handleLinkClick(close)
                                              }
                                            >
                                              {subSubItem.name}
                                            </Link>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <Link
                                      key={subitem.name}
                                      href={subitem.href}
                                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-orange-400 font-semibold"
                                      onClick={() => handleLinkClick(close)}
                                    >
                                      {subitem.name}
                                    </Link>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-black px-0 py-2 rounded-md text-lg font-medium hover:text-orange-400"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <Link
                      className="bg-color-brown-500 py-3 px-4 ml-10 self-center text-white rounded-2xl hover:bg-color-orange-600 text-xs sm:text-sm font-semibold transition-colors duration-300 break-keep"
                      title="Let's Talk"
                      href="/contact-us"
                    >
                      <span className="break-keep">Let's Talk</span>
                    </Link>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item, index) =>
                    item.submenu ? (
                      <Disclosure key={item.name} as="div">
                        {({ open: submenuOpen }) => (
                          <>
                            <Disclosure.Button
                              as="button"
                              className="text-black px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                              onClick={() => handleSubmenuToggle(index)}
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`h-5 w-5 transition-transform inline ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel
                              className={`space-y-1 ${
                                submenuOpen ? "block" : "hidden"
                              }`}
                            >
                              {item.submenu.map((subitem, subIndex) =>
                                subitem.submenu ? (
                                  <Disclosure
                                    key={subitem.name}
                                    as="div"
                                    className="pl-4"
                                  >
                                    {({ open: subSubmenuOpen }) => (
                                      <>
                                        <Disclosure.Button
                                          className="text-black px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                                          onClick={() =>
                                            handleSubmenuToggle(
                                              `${index}-${subIndex}`
                                            )
                                          }
                                        >
                                          {subitem.name}
                                          <ChevronDownIcon
                                            className={`h-5 w-5 transition-transform inline ${
                                              openSubmenu ===
                                              `${index}-${subIndex}`
                                                ? "transform rotate-180"
                                                : ""
                                            }`}
                                          />
                                        </Disclosure.Button>
                                        <Disclosure.Panel
                                          className={`space-y-1 ${
                                            subSubmenuOpen ? "block" : "hidden"
                                          }`}
                                        >
                                          {subitem.submenu.map((subSubItem) => (
                                            <Link
                                              key={subSubItem.name}
                                              href={subSubItem.href}
                                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                              onClick={() =>
                                                handleLinkClick(close)
                                              }
                                            >
                                              {subSubItem.name}
                                            </Link>
                                          ))}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                                ) : (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    onClick={() => handleLinkClick(close)}
                                  >
                                    {subitem.name}
                                  </Link>
                                )
                              )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ) : (
                      <Link
                        key={item.name}
                        href={item.href}
                        className="block text-black px-3 py-2 rounded-md text-sm font-medium"
                        onClick={() => handleLinkClick(close)}
                      >
                        {item.name}
                      </Link>
                    )
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </header>
  );
};

export default Header;
